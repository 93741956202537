import { Component } from '@angular/core';
import { MoveService } from '../shared/services/move.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  constructor(private moveService: MoveService) {
    this.moveService.moves$
      .subscribe(result => {
        if (result.length <= 0) {
          this.moveService.getData('Active');
        }
      });
  }
}
