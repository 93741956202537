import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormFieldErrorComponent } from './components/form-field-error/form-field-error.component';
import { FormLabelRequiredComponent } from './components/form-label-required/form-label-required.component';
import { BaseListComponent } from './components/base-list/base-list.component';
import { BaseFormComponent } from './components/base-form/base-form.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { MaterialModule } from './material.module';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { BaseComponent } from './components/base/base.component';
import { DateRangeComponent } from './components/date-range/date-range.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CompletedDialogComponent } from './components/completed-dialog/completed-dialog.component';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { UtcPipe } from './pipes/utc.pipe';
import { FocusDirective } from './directives/focus.directive';
import { IntegerDirective } from './directives/integer.directive';

@NgModule({
  declarations: [
    FormFieldErrorComponent,
    FormLabelRequiredComponent,
    BaseListComponent,
    BaseFormComponent,
    ConfirmDialogComponent,
    ErrorMessageComponent,
    BaseComponent,
    DateRangeComponent,
    CompletedDialogComponent,
    EllipsisPipe,
    UtcPipe,
    FocusDirective,
    IntegerDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
  ],
  exports: [
    ErrorMessageComponent,
    FormFieldErrorComponent,
    FormLabelRequiredComponent,
    DateRangeComponent,
    EllipsisPipe,
    FocusDirective,
    IntegerDirective,
    UtcPipe,
  ],
  providers: []
})
export class SharedModule { }
