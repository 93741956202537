<div [formGroup]="fg" class="flex flex-row">
  <div class="form-field">
    <label *ngIf="displayLabel" class="form-label" for="from">From</label>
    <div class="flex">
      <input [matDatepicker]="pickerFrom" type="text" placeholder="MM/dd/yyyy" class="form-input w-full rounded shadow"
        name="from" formControlName="from" [max]="maxLimit">
      <div class="-mx-8 -my-1">
        <mat-datepicker-toggle matSuffix [for]="pickerFrom" disableRipple="true"></mat-datepicker-toggle>
      </div>
      <mat-datepicker #pickerFrom></mat-datepicker>
    </div>
    <div *ngIf="fromControl.touched && fromControl.hasError('matDatepickerMax')">
      <small class="text-red-500">
        Invalid starting date range
      </small>
    </div>
  </div>

  <div class="form-field">
    <label *ngIf="displayLabel" class="form-label" for="to">To</label>
    <div class="flex">
      <input [matDatepicker]="pickerTo" type="text" placeholder="MM/dd/yyyy" class="form-input w-full rounded shadow"
        name="to" formControlName="to" [min]="minLimit">
      <div class="-mx-8 -my-1">
        <mat-datepicker-toggle matSuffix [for]="pickerTo" disableRipple="true"></mat-datepicker-toggle>
      </div>
      <mat-datepicker #pickerTo></mat-datepicker>
    </div>
    <div *ngIf="toControl.touched && toControl.hasError('matDatepickerMin')">
      <small class="text-red-500">
        Invalid ending date range
      </small>
    </div>
  </div>
</div>
