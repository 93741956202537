import { Component, OnDestroy } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SelectDto } from 'src/app/web-api-client';

export interface PageOptions {
  title?: string;
  errors?: string;
  activating?: boolean;
  loading?: boolean;
  saving?: boolean;
  deleting?: boolean;
  validForm?: boolean;
  reload?: boolean;
  dialogWidth?: string;
  downloading?: boolean;
  downloadId?: number;
}

@Component({
  selector: 'app-base',
  template: '',
})
export class BaseComponent implements OnDestroy {
  subscriptions: Subscription[] = [];
  pageOptions: PageOptions;

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  ngOnDestroy(): void {
    this.subscriptions.forEach(e => e.unsubscribe());
  }

  getFiltered = (data: SelectDto[], field: AbstractControl): Observable<SelectDto[]> =>
    field.valueChanges
      .pipe(startWith<string | SelectDto>(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this.filter(data, name) : data?.slice()));

  filter = (data: SelectDto[], term: string): SelectDto[] =>
    data.filter(e => e.name?.toLowerCase().indexOf(term.toLocaleLowerCase()) === 0);

  onSelectionClicked = (data: SelectDto[], field: AbstractControl): Observable<SelectDto[]> =>
    this.getFiltered(data, field);

  openSnackBar(snackBar: MatSnackBar, message: string, label: string, duration: number, color: string = 'green-snackbar') {
    console.log(color);
    snackBar.open(message, label, {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: duration * 1000,
      panelClass: color
    });
  }

  setLocalTime(time: Date): Date {
    if(time === null) {
      return null;
    }
    
    let timePart = [time.toString().substring(0, 2), time.toString().substring(2, 4)];
    let currentDate = new Date();
    currentDate.setUTCHours(parseInt(timePart[0]), parseInt(timePart[1]));
    return currentDate;
  }

  formStatus(event: boolean) {
    this.pageOptions.validForm = event;
  }
}
