import { Component } from '@angular/core';
import { AbstractControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-base-form',
  template: '',
})
export class BaseFormComponent extends BaseComponent {
  fg: UntypedFormGroup;

  autocompleteStringValidator(validOptions: Array<string>): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value === null || control.value === '' || validOptions.indexOf(control.value) !== -1) {
        return null
      }
      return { 'invalidAutocomplete': { value: control.value } }
    }
  }

  checkDate(date: Date | string): Date | string {
    return date >= new Date('01/01/1800') ? date : null;
  }

  setTime(date: Date | string): string {
    let time = date >= new Date('01/01/1800') ? date : null;
    return time ? (('0' + (new Date(date).getHours())).slice(-2) + ('0' + (new Date(date).getMinutes())).slice(-2)) : null
  }

  displayErrors(control: AbstractControl): boolean {
    return control.invalid && control.errors && (control.dirty || control.touched)
  }

  isRequired(control: AbstractControl): boolean {
    const validator = control?.validator ? control?.validator({} as AbstractControl) : '';
    if (validator && validator.required) {
      return true;
    }
  }
}
