import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-form-label-required',
  templateUrl: './form-label-required.component.html',
  styleUrls: ['./form-label-required.component.scss']
})
export class FormLabelRequiredComponent {
  @Input() name: string;
  @Input() label: string;
  @Input() isRequired: boolean;
}
