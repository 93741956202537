<div [formGroup]="fg">
  <label for="firstName">First Name</label>
  <input type="text" formControlName="firstName" name="firstName" />

  <label for="lastName">Last Name</label>
  <input type="text" formControlName="lastName" name="lastName" />

  <label for="email">Email</label>
  <input type="email" formControlName="email" name="email" />
  <div class="error" *ngIf="emailControl.touched && emailControl.hasError('required')">Email is required</div>
</div>
