import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appFocus]',
})
export class FocusDirective implements AfterViewInit {
  @Input()
  focusSelectValue = false;

  constructor(private elRef: ElementRef<HTMLInputElement>) { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.elRef.nativeElement.focus();

      if (this.focusSelectValue) {
        this.elRef.nativeElement.setSelectionRange(0, this.elRef.nativeElement.value.length);
      }
    });
  }
}
