import { Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { matchingInputsValidator } from './validator';

export interface PasswordFormValues {
  password: string;
  confirmPassword: string;
}

@Component({
  selector: 'app-password-form',
  templateUrl: './password-form.component.html',
  styleUrls: ['./password-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PasswordFormComponent),
      multi: true,
    }
  ]
})
export class PasswordFormComponent implements OnInit, OnDestroy {
  fg: UntypedFormGroup;
  subscriptions: Subscription[] = [];

  constructor(private fb: UntypedFormBuilder) {
    this.fg = this.fb.group(
      {
        password: ['', Validators.required],
        confirmPassword: ['', Validators.required],
      },
      {
        validator: matchingInputsValidator('password', 'confirmPassword')
      }
    );

    this.subscriptions.push(
      this.fg.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      }));
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(e => e.unsubscribe());
  }

  get value(): PasswordFormValues {
    return this.fg.value;
  }

  set value(value: PasswordFormValues) {
    this.fg.setValue(value);
  }

  get passwordControl(): AbstractControl {
    return this.fg.controls.password;
  }

  get confirmPasswordControl(): AbstractControl {
    return this.fg.controls.confirmPassword;
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.fg.reset();
    }
  }

  validate(_: UntypedFormControl) {
    return this.fg.valid ? null : { passwords: { valid: false } };
  }
}
