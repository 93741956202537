<div class="flex justify-center items-center flex-col bg -m-2 opacity-75 text-white">
  <div class="text-6xl">Offshore and Inland Chartering</div>
  <div class="text-4xl">US-Flagged Vessels</div>
</div>
<div class="mx-48 my-24 text-xl">
  <p class="font-medium">L & R Midland’s Offshore and Inland Chartering departments are skilled in all manner of contracts of affreightment.  We assist both owners and charterers in the transport of Oil products both clean and dirty, clean and water white chemicals and petrochemicals.</p>
  <p>Offshore includes U.S. Flagged ships and offshore barges trading the East Coast, Gulf Coast and West Coast as well as Puerto Rico and the Caribbean.  Inland comprises the Mississippi, Illinois, Missouri, Ohio, and Tennessee Rivers as well as the Tennessee-Tom Bigbee Waterway and the Gulf Intercoastal Waterways.</p>
  <p>Our experienced Chartering Departments provide day-to-day operations, tracking equipment and assist with daily issues as they arise along with the proper documentation and required follow up.. They also provide the unique services of offering inventory management and market research.</p>
  <p>Brokering petrochemicals and petroleum product freight, spot and time charters, affreightment, bareboat charter both for brown and blue water.</p>
</div>
