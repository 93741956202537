import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-base-list',
  template: '',
})
export class BaseListComponent extends BaseComponent {
  fg: UntypedFormGroup;
  displayedColumns: string[];
  pageSize: number[];

  setPageSizeArray(length: number): void {
    if (length > 50) {
      this.pageSize = [5, 10, 20, 50, 100];
      return;
    }
    if (length > 20) {
      this.pageSize = [5, 10, 20, 50];
      return;
    }
    if (length > 10) {
      this.pageSize = [5, 10, 20];
      return;
    }
    if (length > 5) {
      this.pageSize = [5, 10];
      return;
    }
    this.pageSize = [5];
    return;
  }
}
