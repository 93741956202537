import { AfterViewInit, Directive } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'input[integer]'
})
export class IntegerDirective implements AfterViewInit {

  constructor(private ngControl: NgControl) { }

  ngAfterViewInit(): void {
    this.ngControl.valueChanges
      .subscribe(value => {
        const initialValue = value.toString().replace(/[^0-9]/g, '');
        if (value !== initialValue) {
          this.ngControl.control?.setValue(initialValue, { emitEvent: false });
        }
      });
  }

}
