<form [formGroup]="fg" (ngSubmit)="submit()">
  <app-profile-form formControlName="profile"></app-profile-form>
  <app-password-form formControlName="password"></app-password-form>
  <button>Sign Up</button>
</form>

<p>
  Form is {{fg.valid ? 'Valid' : 'Invalid'}}
</p>

<pre>
{{fg.value | json}}
</pre>
