<header>
  <nav>
    <div class="nav-bar">
      <div class="flex justify-between flex-grow">
        <a class="nav-title" [routerLink]="['/']">
          <img src="../../assets/images/lr-site-logo-01.png" class="object-contain h-9" />
        </a>
        <div class="w-full relative flex justify-end md:w-auto px-4 md:static md:block md:justify-end">
          <button
            class="text-white cursor-pointer text-xl leading-none py-1 border border-solid border-transparent rounded bg-transparent block md:hidden outline-none focus:outline-none"
            type="button" (click)="toggle()">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>
      </div>
      <div class="nav-menu" *ngIf="isAuthenticated | async"
        [ngClass]="{'hidden': !isExpanded, 'flex flex-col md:flex-row md:justify-end': isExpanded}"
        class="md:flex mt-2 md:items-center">
        <a class="nav-menu-item justify-start" [routerLink]="['/contracts']">Contracts</a>
        <a class="nav-menu-item" [routerLink]="['/voyages']">Moves</a>
        <a class="nav-menu-item" [routerLink]="['/voyages/list-by-owner']">Traffics</a>
        <a class="nav-menu-item" [routerLink]="['/company']">Company</a>
        <a class="nav-menu-item" [routerLink]="['/maintenance']">Maintenance</a>
        <a class="nav-menu-item" [routerLink]="['/reports']">Reports</a>
        <a class="nav-menu-item" [routerLink]="['/release-notes']">Release Notes</a>
        <a class="nav-menu-item" *ngIf="(role | async) === 'Administrator'" [routerLink]="['/admin']">Admin</a>
      </div>
      <app-login-menu [ngClass]="{'hidden': !isExpanded, 'flex': isExpanded}" class="md:flex mt-2">
      </app-login-menu>
    </div>
  </nav>
</header>
