import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { combineLatest, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { BaseListComponent } from '../shared/components/base-list/base-list.component';
import { ReleasesClient, ReleasesDto } from '../web-api-client';

@Component({
  selector: 'app-release-notes',
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReleaseNotesComponent extends BaseListComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource$: Observable<MatTableDataSource<ReleasesDto>>;
  title = 'Release Notes';
  displayedColumns = ['name', 'notes'];
  searchField = new UntypedFormControl('');

  searchTerm$ = this.searchField.valueChanges
    .pipe(startWith(this.searchField.value));

  filteredBranches$ = combineLatest([this.releasesClient.getLatestReleases(), this.searchTerm$])
    .pipe(map(([releases, searchTerm]) => releases
      .filter((release) =>
        searchTerm === '' ||
        release.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        release.notes?.toLowerCase().includes(searchTerm.toLowerCase()))
    ));

  constructor(private releasesClient: ReleasesClient) {
    super();
  }

  ngOnInit(): void {
    this.dataSource$ = this.filteredBranches$
      .pipe(map(releases => {
        const dataSource = new MatTableDataSource<ReleasesDto>();
        dataSource.data = releases;
        dataSource.paginator = this.paginator;
        dataSource.sort = this.sort;
        return dataSource;
      }));
  }
}
