<div [formGroup]="fg">
  <label for="password">Password</label>
  <input formControlName="password" type="password" id="password" />
  <div *ngIf="passwordControl.touched && passwordControl.hasError('required')" class="error">
    password is required
  </div>

  <label for="confirm-password">Confirm Password</label>
  <input formControlName="confirmPassword" type="password" id="confirm-password" />
  <div *ngIf="confirmPasswordControl.touched && confirmPasswordControl.hasError('required')" class="error">
    password is required
  </div>

  <div *ngIf="passwordControl.touched && confirmPasswordControl.touched && fg.hasError('missmatch')" class="error">
    passwords do not match
  </div>
</div>
