import { Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

export interface ProfileFormValues {
  firstName: string;
  lastName: string;
  email: string;
}

@Component({
  selector: 'app-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProfileFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ProfileFormComponent),
      multi: true,
    }
  ]
})
export class ProfileFormComponent implements OnInit, OnDestroy {
  fg: UntypedFormGroup;
  subscriptions: Subscription[] = [];

  constructor(private fb: UntypedFormBuilder) {
    this.fg = this.fb.group({
      firstName: [],
      lastName: [],
      email: ['', Validators.required],
    });

    this.subscriptions.push(
      this.fg.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    )
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(e => e.unsubscribe());
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.fg.reset();
    }
  }

  validate(_: UntypedFormControl) {
    return this.fg.valid ? null : { profile: { valid: false } };
  }

  get value(): ProfileFormValues {
    return this.fg.value;
  }

  set value(value: ProfileFormValues) {
    this.fg.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  get emailControl(): AbstractControl {
    return this.fg.controls.email;
  }

  onChange: any = () => { };
  onTouched: any = () => { };

}
