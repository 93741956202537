<div class="flex justify-center p-2">
  <div class="card w-8/12">
    <div class="card-title">{{title}}</div>

    <div class="card-content">
      <div class="w-64">
        <input type="text" placeholder="Start typing to search..." class="form-input w-full rounded shadow"
          [formControl]="searchField" />
      </div>

      <div class="mat-elevation-z3 my-2">
        <table mat-table [dataSource]="dataSource$ | async" matSort>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let element">
              <div class="font-medium">{{ element.name }}</div>
            </td>
          </ng-container>

          <ng-container matColumnDef="notes">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Notes</th>
            <td mat-cell *matCellDef="let element">
              <div class="font-medium">
                {{ element.notes }}
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="2">No data</td>
          </tr>
        </table>

        <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons aria-label="Select page of roles">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
