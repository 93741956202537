import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

export interface DateRangeValues {
  from: Date;
  to: Date;
}

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangeComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DateRangeComponent),
      multi: true,
    }
  ]
})
export class DateRangeComponent implements OnDestroy {
  @Input()
  set showLabel(data: boolean) {
    this.displayLabel = data;
  }
  fg: UntypedFormGroup;
  subscription: Subscription[] = [];
  minLimit: Date;
  maxLimit: Date;
  displayLabel = true;

  constructor(private fb: UntypedFormBuilder, private cdRef: ChangeDetectorRef) {
    this.fg = this.fb.group({
      from: [],
      to: [],
    });

    this.subscription.push(
      this.fg.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe(value => {
          this.minLimit = value.from;
          this.maxLimit = value.to;
          this.cdRef.detectChanges();
          this.onChange(value);
          this.onTouched()
        }));
  }

  ngOnDestroy(): void {
    this.subscription.forEach(e => e.unsubscribe());
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  get fromControl(): AbstractControl {
    return this.fg.controls.from;
  }

  get toControl(): AbstractControl {
    return this.fg.controls.to;
  }

  set value(value: DateRangeValues) {
    this.fg.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  get value(): DateRangeValues {
    return this.fg.value;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.fg.reset();
    }
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  validate(_: UntypedFormControl) {
    return this.fg.valid ? null : { dateRange: { valid: false } };
  }
}
