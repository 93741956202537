import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'utc'
})
export class UtcPipe implements PipeTransform {

  transform(value: Date, limit: number): Date {
    if (limit && value) {
      const date = new Date(value);
      return new Date(date.setHours(date.getHours() + limit));
    }

    return value;
  }
}
