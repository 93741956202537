import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ProfileFormValues } from '../profile-form/profile-form.component';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  fg: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {
    this.fg = this.fb.group({
      password: [],
      profile: [],
    });
  }

  ngOnInit(): void {
    this.fg.patchValue({
      profile: {
        firstName: 'Joel',
        lastName: 'Asperin',
        email: 'joel.asperin@gmail.com',
      } as ProfileFormValues
    });
  }

  submit() {
  }
}
