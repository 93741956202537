<h3>JWT</h3>

<div *ngIf="!isError">
  <p>
    This component demonstrates interacting with the authorization service to
    retrieve your
    <a href="https://jwt.io/" target="_blank">JSON web token (JWT)</a>.
  </p>
  <div class="card">
    <div class="card-body">
      <p id="tokenContent">{{ token }}</p>
    </div>
  </div>
  <br />
  <p>
    <button class="btn btn-primary" (click)="copyToClipboard()">
      Copy
    </button>
  </p>
  <p>
    <span *ngIf="isCopied">Copied!</span>
  </p>
</div>

<div *ngIf="isError">
  <div class="alert alert-danger" role="alert">Error getting JWT</div>
  <p>
    Something went wrong getting your access token from the auth service. Please
    try again.
  </p>
</div>
