<div *ngIf="isAuthenticated | async" class="flex flex-col md:flex-row">
  <div class="md:hidden">
    <a class="nav-menu-item hover:text-white"
      [ngClass]="{'text-red-500 hover:text-red-500': (role | async) === 'Administrator'}">{{ userName | async }}</a>
    <a class="nav-menu-item" [routerLink]='["/authentication/logout"]' [state]='{ local: true }'>Logout</a>
  </div>
  <div class="hidden md:block">
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon aria-hidden="false" aria-label="profile">account_circle</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item [routerLink]='["/authentication/profile"]'[ngClass]="{'text-red-500 hover:text-red-500': (role | async) === 'Administrator'}">
        Profile
      </button>
      <button mat-menu-item [routerLink]='["/authentication/logout"]' [state]='{ local: true }'>
        <mat-icon aria-hidden="false" aria-label="profile">power_settings_new</mat-icon>Logout
      </button>
    </mat-menu>
  </div>
</div>
<div *ngIf="!(isAuthenticated | async)">
  <a class="nav-menu-item" [routerLink]='["/authentication/login"]'>Login</a>
</div>
