import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdministratorGuard } from 'src/api-authorization/administrator.guard';
import { AuthorizeGuard } from 'src/api-authorization/authorize.guard';
import { HomeComponent } from './home/home.component';
import { ReleaseNotesComponent } from './release-notes/release-notes.component';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'release-notes', component: ReleaseNotesComponent, canActivate: [AuthorizeGuard] },
  {
    path: 'admin', canActivate: [AuthorizeGuard, AdministratorGuard],
    loadChildren: () => import("./admin/admin.module").then(m => m.AdminModule)
  },
  {
    path: 'maintenance', canActivate: [AuthorizeGuard],
    loadChildren: () => import("./maintenance/maintenance.module").then(m => m.MaintenanceModule)
  },
  {
    path: 'company', canActivate: [AuthorizeGuard],
    loadChildren: () => import("./company/company.module").then(m => m.CompanyModule)
  },
  {
    path: 'contracts', canActivate: [AuthorizeGuard],
    loadChildren: () => import("./contracts/contracts.module").then(m => m.ContractsModule)
  },
  {
    path: 'voyages', canActivate: [AuthorizeGuard],
    loadChildren: () => import("./voyages/voyages.module").then(m => m.VoyagesModule)
  },
  {
    path: 'reports', canActivate: [AuthorizeGuard],
    loadChildren: () => import("./reports/reports.module").then(m => m.ReportsModule)
  },
  {
    path: 'custom-reports', canActivate: [AuthorizeGuard],
    loadChildren: () => import("./custom-report/custom-reports.module").then(m => m.CustomReportsModule)
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
